import { Directive, Self, Output, EventEmitter, Input, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import compare from 'just-compare';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

///this directive ensures that the date value will always be the luxon.
@Directive({
    selector: '[datePickerLuxonModifier]'
})
export class DatePickerLuxonModifierDirective implements OnDestroy, OnChanges {
    @Input() date = new EventEmitter();
    @Output() dateChange = new EventEmitter();

    subscribe: Subscription;
    lastDate: Date = null;

    constructor(
        @Self() private bsDatepicker: BsDatepickerDirective,
        private _dateTimeService: DateTimeService
    ) {
        this.subscribe = bsDatepicker.bsValueChange
            .subscribe((date: Date) => {
                if (!date) {
                    this.lastDate = null;
                    this.dateChange.emit(null);
                } else if ((date instanceof Date && !compare(this.lastDate, date) && date.toString() !== 'Invalid Date')) {
                    const utcDate = this._dateTimeService.toUtcDate(date);
                    this.lastDate = date;
                    this.dateChange.emit(utcDate);
                }
            });
    }

    ngOnDestroy() {
        this.subscribe.unsubscribe();
    }


    ngOnChanges({ date }: SimpleChanges) {
        if (date && date.currentValue && date.currentValue.isValid) {
            setTimeout(() => {
                if (!compare(date.currentValue, date.previousValue)) {
                    let year = date.currentValue.year;
                    let month = date.currentValue.month - 1;
                    let day = date.currentValue.day;
                    const jsDate = this._dateTimeService.createJSDate(year, month, day);
                    this.bsDatepicker.bsValue = jsDate;
                }
            }, 0);
        }
    }
}
